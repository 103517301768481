import React, { useState, Suspense, lazy } from "react";
import Topbar from "./Topbar";
import { Box } from "@mui/system";
import { Routes, Route, Navigate } from "react-router-dom";
import UserRole from "../user/UserRole";
import GetUserRole from "../../utils/GetUserRole";
import GlobalLoader from "../../components/GlobalLoader";
import { shallowEqual, useSelector } from "react-redux";

const ProductManagement = lazy(() => import("../manage-product/ProductManagement"));
const SubscriptionHIstory = lazy(() => import("../subscription/history/SubscriptionHistory"));
const StoreAddModal = lazy(() => import("../store-management/StoreAddModal"));
const StoreRoles = lazy(() => import("../store-management/StoreRoles"));
const SupportView = lazy(() => import("../support/SupportView"));
const StoreProfile = lazy(() => import("../store-management/StoreProfile"));
const CategoryTaxTree = lazy(() => import("../store-management/CategoryTaxTree"));
// const Statement = lazy(() => import("../statement/StatementTable"));
const ProductReport = lazy(() => import("../product/ProductReport"));
const DecryptComponent = lazy(() => import("../../components/DecryptComponent"));
const Store = lazy(() => import("../store-management/StoreTable"));
const Settings = lazy(() => import("../settings"));
const SubscriptionList = lazy(() => import("../subscription/SubscriptionList"));
const SidebarComponent = lazy(() => import("./DoorSidebar"));
const InventoryView = lazy(() => import("../inventory/InventoryView"));
const Inventory = lazy(() => import("../inventory/InventoryTable"));
const Vendor = lazy(() => import("../vendor/VendorTable"));
const CustomerView = lazy(() => import("../customer/customerview/CustomerView"));
const Customer = lazy(() => import("../customer/CustomerTable"));
const User = lazy(() => import("../user/UserTable"));
const ProductView = lazy(() => import("../product/ProductView"));
const Product = lazy(() => import("../product/ProductTable"));
const Category = lazy(() => import("../category/CategoryTable"));
const Tax = lazy(() => import("../tax/TaxTable"));
const Driver = lazy(() => import("../driver/DriverTable"));
const Brand = lazy(() => import("../brand/BrandTable"));
const Dashboard = lazy(() => import("../dashboard"));
const OrderManagement = lazy(() => import("../order-management/OrderTable"));
const OrderView = lazy(() => import("../order-management/OrderView"));
const Profile = lazy(() => import("./Profile"));
const Support = lazy(() => import("../support/SupportTable"));
const DriverProfile = lazy(() => import("../driver/DriverProfile"));
const LocationReport = lazy(() => import("../store-management/LocationReport"));
const InventoryAdd = lazy(() => import("../inventory/InventoryAdd"));

export default function Layout() {
  const [toggled, setToggled] = useState(false);
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const { ShowTopBar = true, showSideBar = true } = useSelector((state) => state && state.auth, shallowEqual);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
      <Suspense fallback={null}>
        <Box
          sx={{
            minWidth: 0,
            flex: "1 0 auto",
            overflowY: "auto"
          }}
          height="100vh"
        >
          <SidebarComponent toggled={toggled} handleToggleSidebar={handleToggleSidebar} showSideBar={showSideBar} />
        </Box>
      </Suspense>
      <Box sx={{ minWidth: 0, flex: "0 1 auto", overflowY: "auto" }} height="100vh" width="100%">
        {ShowTopBar && <Topbar handleToggleSidebar={handleToggleSidebar} />}
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route exact path="/dashboard" element={<Dashboard />} />
            {GetUserRole("user_list") && <Route exact path="/users" element={<User />} />}
            {user?.userType === "admin" && <Route path="/users/role/:id" element={<UserRole />} />}
            {<Route path="/profile" element={<Profile />} />}
            {GetUserRole("customer_list") && <Route path="/customers" element={<Customer />} />}
            {GetUserRole("customer_view") && <Route path="/customers/view/:id" element={<CustomerView />} />}
            {GetUserRole("category_list") && <Route exact path="/category" element={<Category />} />}
            {GetUserRole("tax_list") && <Route exact path="/tax" element={<Tax />} />}
            {GetUserRole("driver_list") && <Route exact path="/driver" element={<Driver />} />}
            {GetUserRole("driver_view") && <Route path="/driver/view/:id" element={<DriverProfile />} />}
            {GetUserRole("brand_list") && <Route exact path="/brand" element={<Brand />} />}
            {GetUserRole("product_list") && <Route exact path="/products" element={<Product />} />}
            {GetUserRole("product_view") && <Route exact path="/products/view/:id" element={<ProductView />} />}
            {GetUserRole("vendor_list") && <Route exact path="/vendors" element={<Vendor />} />}
            {GetUserRole("inventory_list") && <Route exact path="/inventory" element={<Inventory />} />}
            {GetUserRole("inventory_list") && <Route exact path="/inventory/:id" element={<Inventory />} />}
            {GetUserRole("inventory_add") && <Route exact path="/inventory/add" element={<InventoryAdd />} />}
            {GetUserRole("inventory_view") && <Route exact path="/inventory/view/:id" element={<InventoryView />} />}
            {GetUserRole("order_list") && <Route exact path="/orders" element={<OrderManagement />} />}
            {GetUserRole("order_view") && <Route exact path="/orders/view/:id" element={<OrderView />} />}
            {GetUserRole("store_list") && <Route exact path="/locations" element={<Store />} />}
            {GetUserRole("product_add") && <Route exact path="/products/add" element={<ProductManagement />} />}
            {GetUserRole("product_edit") && <Route exact path="/products/edit/:id" element={<ProductManagement />} />}
            {GetUserRole("store_add") && <Route exact path="/locations/add" element={<StoreAddModal />} />}
            {GetUserRole("store_tax_comm_view") &&
              (user?.userType === "store" ? (
                <Route exact path="/locationTax" element={<CategoryTaxTree />} />
              ) : (
                <Route exact path="/locations/locationTax/:id" element={<CategoryTaxTree />} />
              ))}
            {GetUserRole("store_edit") && <Route exact path="/locations/edit/:id" element={<StoreAddModal />} />}
            {GetUserRole("store_view") && <Route exact path="/locations/view/:id" element={<StoreProfile />} />}

            {GetUserRole("category_product_tree") &&
              (user?.userType === "store" ? (
                <Route exact path="/locations" element={<StoreRoles />} />
              ) : (
                <Route exact path="/locations/tree/:id" element={<StoreRoles />} />
              ))}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
            {GetUserRole("support_list") && <Route exact path="/supports" element={<Support />} />}
            {GetUserRole("support_view") && <Route exact path="/supports/:id" element={<SupportView />} />}
            {/* {GetUserRole("statement_all_view") && <Route exact path="/statement" element={<Statement />} />} */}
            {GetUserRole("location_report") && <Route exact path="/location_report" element={<LocationReport />} />}
            {GetUserRole("product_view") && <Route exact path="/report/product" element={<ProductReport />} />}
            {GetUserRole("access_setting") && <Route exact path="/settings" element={<Settings />} />}
            {GetUserRole("subscriptionPlan_list") && (
              <Route exact path="/subscription" element={<SubscriptionList />} />
            )}
            {GetUserRole("subscriptionPlan_list") && (
              <Route exact path="/subscription-history" element={<SubscriptionHIstory />} />
            )}
            <Route exact path="/decrypt" element={<DecryptComponent />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
}
