import { combineReducers } from "redux";
import * as auth from "../pages/login/redux/reducer.js";
import * as categories from "../pages/category/redux/reducer.js";
import * as taxs from "../pages/tax/redux/reducer.js";
import * as drivers from "../pages/driver/redux/reducer.js";
import * as brands from "../pages/brand/redux/reducer";
import * as products from "../pages/product/redux/reducer.js";
import * as users from "../pages/user/redux/reducer.js";
import * as customers from "../pages/customer/redux/reducer.js";
import * as subProducts from "../pages/subproduct/redux/reducer.js";
import * as vendors from "../pages/vendor/redux/reducer.js";
import * as inventory from "../pages/inventory/redux/reducer.js";
import * as orders from "../pages/order-management/redux/reducer.js";
import * as layout from "../pages/global/redux/reducer.js";
import * as dashboard from "../pages/dashboard/redux/reducer.js";
import * as notification from "../pages/global/redux/reducer.js";
import * as store from "../pages/store-management/redux/reducer.js";
import * as support from "../pages/support/redux/reducer.js";
import * as statement from "../pages/statement/redux/reducer.js";
import * as settings from "../pages/settings/redux/reducer.js";
import * as subscription from "../pages/subscription/redux/reducer.js";

const appReducer = combineReducers({
  auth: auth.reducer,
  categories: categories.reducer,
  taxs: taxs.reducer,
  drivers: drivers.reducer,
  brands: brands.reducer,
  products: products.reducer,
  users: users.reducer,
  customers: customers.reducer,
  subProducts: subProducts.reducer,
  vendors: vendors.reducer,
  inventory: inventory.reducer,
  orders: orders.reducer,
  layout: layout.reducer,
  dashboard: dashboard.reducer,
  notification: notification.reducer,
  store: store.reducer,
  support: support.reducer,
  statement: statement.reducer,
  settings: settings.reducer,
  subscription: subscription.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    // for all keys defined in your persistConfig(s)
    localStorage.clear();
    // storage.removeItem("persist:v713-demo1-auth");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
