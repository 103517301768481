// user module types
export const subscription = {
  SUBSCRIPTION_REQUEST: "SUBSCRIPTION_REQUEST",
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_FAIL: "SUBSCRIPTION_FAIL",

  SUBSCRIPTION_ADD_REQUEST: "SUBSCRIPTION_ADD_REQUEST",
  SUBSCRIPTION_ADD_SUCCESS: "SUBSCRIPTION_ADD_SUCCESS",
  SUBSCRIPTION_ADD_FAIL: "SUBSCRIPTION_ADD_FAIL",

  SUBSCRIPTION_HISTORY_REQUEST: "SUBSCRIPTION_HISTORY_REQUEST",
  SUBSCRIPTION_HISTORY_SUCCESS: "SUBSCRIPTION_HISTORY_SUCCESS",
  SUBSCRIPTION_HISTORY_FAIL: "SUBSCRIPTION_HISTORY_FAIL",

  GET_PLAN_DRP_REQUEST: "GET_PLAN_DRP_REQUEST",
  GET_PLAN_DRP_SUCCESS: "GET_PLAN_DRP_SUCCESS",
  GET_PLAN_DRP_FAIL: "GET_PLAN_DRP_FAIL",

  PDF_GET_REQUEST: "PDF_GET_REQUEST",
  PDF_GET_SUCCESS: "PDF_GET_SUCCESS",
  PDF_GET_FAIL: "PDF_GET_FAIL",

  CHANGE_SUBSCRIPTION_TAB: "CHANGE_SUBSCRIPTION_TAB"
};
