export const settingsType = {
  // get payment
  GET_PAYMENT_TYPE_REQUEST: " GET_PAYMENT_TYPE_REQUEST",
  GET_PAYMENT_TYPE_SUCCESS: " GET_PAYMENT_TYPE_SUCCESS",
  GET_PAYMENT_TYPE_FAIL: " GET_PAYMENT_TYPE_FAIL",

  // change payment
  CHANGE_PAYMENT_TYPE_REQUEST: "CHANGE_PAYMENT_TYPE_REQUEST",
  CHANGE_PAYMENT_TYPE_SUCCESS: "CHANGE_PAYMENT_TYPE_SUCCESS",
  CHANGE_PAYMENT_TYPE_FAIL: "CHANGE_PAYMENT_TYPE_FAIL",
  // get payment
  GET_DELIVERY_INFO_REQUEST: "GET_DELIVERY_INFO_REQUEST",
  GET_DELIVERY_INFO_SUCCESS: "GET_DELIVERY_INFO_SUCCESS",
  GET_DELIVERY_INFO_FAIL: "GET_DELIVERY_INFO_FAIL",

  // change payment
  CHANGE_DELIVERY_INFO_REQUEST: "CHANGE_DELIVERY_INFO_REQUEST",
  CHANGE_DELIVERY_INFO_SUCCESS: "CHANGE_DELIVERY_INFO_SUCCESS",
  CHANGE_DELIVERY_INFO_FAIL: "CHANGE_DELIVERY_INFO_FAIL",
  // get payment
  GET_MIN_ORDER_INFO_REQUEST: "GET_MIN_ORDER_INFO_REQUEST",
  GET_MIN_ORDER_INFO_SUCCESS: "GET_MIN_ORDER_INFO_SUCCESS",
  GET_MIN_ORDER_INFO_FAIL: "GET_MIN_ORDER_INFO_FAIL",

  // change payment
  CHANGE_MIN_ORDER_INFO_REQUEST: "CHANGE_MIN_ORDER_INFO_REQUEST",
  CHANGE_MIN_ORDER_INFO_SUCCESS: "CHANGE_MIN_ORDER_INFO_SUCCESS",
  CHANGE_MIN_ORDER_INFO_FAIL: "CHANGE_MIN_ORDER_INFO_FAIL"
};
