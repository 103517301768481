import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

export default function LogoutDialog({ openLogout, onHandleLogout, handleLogoutClose }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      open={openLogout}
      onClose={handleLogoutClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleLogoutClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle variant="h4" sx={{ alignItems: "center", display: "flex", p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            alignItems: "center",
            display: "flex",
            textTransform: "capitalize"
          }}
        >
          <ErrorIcon size="medium" className="danger" />
          Logout ?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">Are you sure you want to logout?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleLogoutClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={[
            {
              "&:hover": {
                bgcolor: colors.themeSecondaryBlueColor[100],
                color: colors.white[100]
              }
            },
            { bgcolor: colors.themeDarkGrey[100] }
          ]}
          onClick={onHandleLogout}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
