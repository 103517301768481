import { subscription } from "./type";

const initialProducts = {
  subscriptionData: [],
  isLoading: false,
  error: null,
  total: null,
  subscriptionHistory: [],
  activeTab: 0,
  isDropDownLoading: false,
  planDropDown: []
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case subscription.SUBSCRIPTION_REQUEST:
      return { ...state, isLoading: true };
    case subscription.SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscriptionData: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case subscription.SUBSCRIPTION_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case subscription.SUBSCRIPTION_HISTORY_REQUEST:
      return { ...state, isLoading: true };
    case subscription.SUBSCRIPTION_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscriptionHistory: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case subscription.SUBSCRIPTION_HISTORY_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case subscription.SUBSCRIPTION_ADD_REQUEST:
      return { ...state, isLoading: true };
    case subscription.SUBSCRIPTION_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case subscription.SUBSCRIPTION_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case subscription.GET_PLAN_DRP_REQUEST:
      return { ...state, isDropDownLoading: true };
    case subscription.GET_PLAN_DRP_SUCCESS: {
      return {
        ...state,
        isDropDownLoading: false,
        planDropDown: action.payload.data
      };
    }
    case subscription.GET_PLAN_DRP_FAIL:
      return { ...state, isDropDownLoading: false, error: action.error };
    case subscription.PDF_GET_REQUEST:
      return { ...state, isLoading: true };
    case subscription.PDF_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case subscription.PDF_GET_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case subscription.CHANGE_SUBSCRIPTION_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
};
