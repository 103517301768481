import { CustomerMap } from "./type";

// category intial state
const initialProducts = {
  customers: null,
  customer: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  customersDropdownData: [],
  isSingleLoading: false,
  isCoinLoading: false,
  coinHistory: [],
  subscriptionHistory: []
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case CustomerMap.CUSTOMERS_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customers: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case CustomerMap.CUSTOMERS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerMap.CUSTOMER_REQUEST:
      return { ...state, isSingleLoading: true };
    case CustomerMap.CUSTOMER_SUCCESS: {
      return {
        ...state,
        isSingleLoading: false,
        customer: action.payload.data
      };
    }
    case CustomerMap.CUSTOMER_FAIL:
      return { ...state, isSingleLoading: false, error: action.error };
    case CustomerMap.CUSTOMER_COIN_REQUEST:
      return { ...state, isCoinLoading: true };
    case CustomerMap.CUSTOMER_COIN_SUCCESS: {
      return {
        ...state,
        isCoinLoading: false,
        coinHistory: action?.payload?.data
      };
    }
    case CustomerMap.CUSTOMER_COIN_FAIL:
      return { ...state, isCoinLoading: false, error: action.error };
    case CustomerMap.CUSTOMERS_ADD_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMERS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case CustomerMap.CUSTOMERS_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerMap.CUSTOMERS_SUBSCRIPTION_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMERS_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionHistory: action?.payload?.data?.records,
        isLoading: false
      };
    }
    case CustomerMap.CUSTOMERS_SUBSCRIPTION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerMap.CUSTOMERS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMERS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case CustomerMap.CUSTOMERS_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerMap.CUSTOMERS_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMERS_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case CustomerMap.CUSTOMERS_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerMap.CUSTOMER_ADDRESS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CustomerMap.CUSTOMER_ADDRESS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case CustomerMap.CUSTOMER_ADDRESS_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case CustomerMap.GET_CUSTOMERS_DROPDOWN_REQUEST:
      return { ...state, isSingleLoading: true };
    case CustomerMap.GET_CUSTOMERS_DROPDOWN_SUCCESS: {
      return {
        ...state,
        isSingleLoading: false,
        customersDropdownData: action.payload.data
      };
    }
    case CustomerMap.GET_CUSTOMERS_DROPDOWN_FAIL:
      return { ...state, isSingleLoading: false, error: action.error };
    default:
      return state;
  }
};
