import "../../pages/login/style.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { signin } from "./redux/action";
import { useNavigate } from "react-router-dom";
import { TextField, FormControl, InputAdornment, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { useEffect, useState } from "react";
import CaptchComponent from "./CaptchComponent";
import logo from "../../media/Images/logo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function Login() {
  const [captcha, setCaptcha] = useState("");
  const [captchaModal, setCaptchaModal] = useState(false);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth, shallowEqual);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setLoading] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (captcha !== "") {
      setCaptchaModal(true);
    }
  }, [captcha]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      await dispatch(signin(data?.email.trim(), data?.password)).then(async (data) => {
        if (data && data?.payload?.status) {
          toast.success(data.payload.message);
          navigate("/dashboard");
        }
        if (data.data?.captchaCode) {
          await setCaptcha(data.data?.captchaCode);
        }
        setLoading(false);
      });
    }
  };
  return (
    <>
      <div className="loginMain">
        {/* <div className="pen-title">
          <h1>Orbi Vape</h1>
        </div> */}
        <div className="logo">
          <img src={logo} alt="logo" style={{ maxWidth: "70%" }} />
        </div>

        <div className="container">
          <div className="card">
            <h1 className="title">Login</h1>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="input-container">
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    {...register("email", { required: true })}
                  />
                  {errors.email && errors.email.type === "required" && <ErrorMSG text="Email is required" />}
                </FormControl>
              </div>
              <div className="input-container">
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...register("password", { required: true })}
                  />
                  {errors.password && errors.password.type === "required" && <ErrorMSG text="Password is required" />}
                </FormControl>
              </div>
              <div className="button-container">
                <button type="submit" disabled={loading}>
                  Sign In
                </button>
              </div>
              <div className="footer">{/* <NavLink to="#">Forgot your password?</NavLink> */}</div>
            </form>
          </div>
        </div>
      </div>
      {captchaModal && (
        <CaptchComponent
          open={captchaModal}
          handleClose={() => {
            setCaptchaModal(false);
            setCaptcha("");
          }}
          captcha={captcha}
          setCaptcha={setCaptcha}
          isLoading={isLoading}
          email={watch().email}
        />
      )}
    </>
  );
}
