import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor from "../../../utils/JsonInterceptor";
import {
  AUTH_CHEK_FAIL,
  AUTH_CHEK_REQUEST,
  AUTH_CHEK_SUCCESS,
  CHEK_CAPTCHA_FAIL,
  CHEK_CAPTCHA_REQUEST,
  CHEK_CAPTCHA_SUCCESS,
  LOG_OUT,
  PASSWORD_CHANGE_FAIL,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  REFRESH_CAPTCHA_FAIL,
  REFRESH_CAPTCHA_REQUEST,
  REFRESH_CAPTCHA_SUCCESS,
  RESET_PASSWORD_REQUEST,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_RESEND_REQUEST,
  RESET_PASSWORD_RESEND_SUCCESS,
  RESET_PASSWORD_RESEND_FAIL,
  HIDE_TOP_BAR,
  HIDE_SIDE_BAR,
  SHOW_SIDE_BAR
} from "./type";

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await JsonInterceptor.post(`api/v2/auth/login`, {
      email,
      password
    });

    localStorage.setItem("userInfo", JSON.stringify(data.data));
    return dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error?.data?.message ? error?.data?.message : error?.message;
    toast.error(message);
    return dispatch({ type: USER_SIGNIN_FAIL, error: message, data: error?.data?.data });
  }
};

export const logout = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: LOG_OUT
    });
  } catch (error) {}
};

//authCheck
export const authCheck = () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_CHEK_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheck}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    await localStorage.setItem("userInfo", JSON.stringify(data.data));
    return dispatch({
      type: AUTH_CHEK_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && (error?.data?.message || error.message);
    toast.error(message);
    return dispatch({ type: AUTH_CHEK_FAIL, error: message });
  }
};

//authRefreshCaptcha
export const authRefreshCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: REFRESH_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authRefreshCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: REFRESH_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: REFRESH_CAPTCHA_FAIL, error: message });
  }
};

//authCheckCaptcha
export const authCheckCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: CHEK_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheckCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CHEK_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CHEK_CAPTCHA_FAIL, error: message });
  }
};

// confirm password
export const addConfirmPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: PASSWORD_CHANGE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.changePassword}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PASSWORD_CHANGE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    return dispatch({ type: PASSWORD_CHANGE_FAIL, error: message });
  }
};

// reset password
export const resetPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.resetPassword}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: RESET_PASSWORD_FAIL, error: message });
  }
};

// reset password
export const resetPasswordResend = (body) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_RESEND_REQUEST });
    let apiEndpoint = `${API_END_POINTS.resetPasswordResend}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: RESET_PASSWORD_RESEND_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: RESET_PASSWORD_RESEND_FAIL, error: message });
  }
};
export const hideTopBar = (body) => async (dispatch) => {
  dispatch({ type: HIDE_TOP_BAR });
};
export const hideSideBar = () => async (dispatch) => {
  dispatch({ type: HIDE_SIDE_BAR });
};
export const toggleSideBar = (data) => async (dispatch) => {
  dispatch({ type: SHOW_SIDE_BAR, payload: data });
};
