import { settingsType } from "./type";

// category intial state
const initial = {
  isLoading: false,
  error: null,
  settings: null
};

export const reducer = (state = initial, action) => {
  switch (action.type) {
    case settingsType.GET_PAYMENT_TYPE_REQUEST:
      return { ...state, isLoading: true };
    case settingsType.GET_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: action.payload
      };
    }
    case settingsType.GET_PAYMENT_TYPE_FAIL:
      return { ...state, isLoading: false, error: action?.error };
    case settingsType.GET_DELIVERY_INFO_REQUEST:
      return { ...state, isLoading: true };
    case settingsType.GET_DELIVERY_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: action.payload
      };
    }
    case settingsType.GET_DELIVERY_INFO_FAIL:
      return { ...state, isLoading: false, error: action?.error };
    case settingsType.GET_MIN_ORDER_INFO_REQUEST:
      return { ...state, isLoading: true };
    case settingsType.GET_MIN_ORDER_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: action.payload
      };
    }
    case settingsType.GET_MIN_ORDER_INFO_FAIL:
      return { ...state, isLoading: false, error: action?.error };
    default:
      return state;
  }
};
