import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Badge, Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#fff" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export default function NotificationAccordian({ notificationData, toggleDrawer }) {
  // const order_view = GetUserRole("order_view");
  // const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {notificationData?.outOfStock?.length > 0 && (
        <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="notifictionAccHead">
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Out Of Stock</Typography>
              {notificationData?.length > 0 && (
                <Badge className="accordianNotificationBadge">{notificationData?.length}</Badge>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails className="notificationAccDetails">
            {notificationData?.outOfStock?.map((text, i) => {
              return (
                <Box
                  key={`list-1${i}`}
                  className="notifyListItem"
                  bgcolor={i % 2 ? "#f2f0f0" : "transparent"}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="8px 16px"
                  component="button"
                  sx={{ border: "none", textAlign: "left" }}
                >
                  <Box>
                    <Box mb="4px">
                      <Typography variant="h6" fontWeight="600">
                        <strong
                          style={{
                            color: colors.greenAccent[500]
                          }}
                        >
                          {` ${text?.subProductData.name} `}
                        </strong>
                        Is Out of Stock
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
