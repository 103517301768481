// user module types
export const CustomerMap = {
  CUSTOMERS_REQUEST: "CUSTOMERS_REQUEST",
  CUSTOMERS_SUCCESS: "CUSTOMERS_SUCCESS",
  CUSTOMERS_FAIL: "CUSTOMERS_FAIL",

  CUSTOMER_REQUEST: "CUSTOMER_REQUEST",
  CUSTOMER_SUCCESS: "CUSTOMER_SUCCESS",
  CUSTOMER_FAIL: "CUSTOMER_FAIL",

  CUSTOMER_COIN_REQUEST: "CUSTOMER_COIN_REQUEST",
  CUSTOMER_COIN_SUCCESS: "CUSTOMER_COIN_SUCCESS",
  CUSTOMER_COIN_FAIL: "CUSTOMER_COIN_FAIL",

  CUSTOMERS_SUBSCRIPTION_REQUEST: "CUSTOMERS_SUBSCRIPTION_REQUEST",
  CUSTOMERS_SUBSCRIPTION_SUCCESS: "CUSTOMERS_SUBSCRIPTION_SUCCESS",
  CUSTOMERS_SUBSCRIPTION_FAIL: "CUSTOMERS_SUBSCRIPTION_FAIL",

  CUSTOMERS_ADD_REQUEST: "CUSTOMERS_ADD_REQUEST",
  CUSTOMERS_ADD_SUCCESS: "CUSTOMERS_ADD_SUCCESS",
  CUSTOMERS_ADD_FAIL: "CUSTOMERS_ADD_FAIL",

  CUSTOMERS_UPDATE_REQUEST: "CUSTOMERS_UPDATE_REQUEST",
  CUSTOMERS_UPDATE_SUCCESS: "CUSTOMERS_UPDATE_SUCCESS",
  CUSTOMERS_UPDATE_FAIL: "CUSTOMERS_UPDATE_FAIL",

  CUSTOMERS_DELETE_REQUEST: "CUSTOMERS_DELETE_REQUEST",
  CUSTOMERS_DELETE_SUCCESS: "CUSTOMERS_DELETE_SUCCESS",
  CUSTOMERS_DELETE_FAIL: "CUSTOMERS_DELETE_FAIL",

  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAIL: "GET_ADDRESS_FAIL",

  CUSTOMER_ADDRESS_UPDATE_REQUEST: "CUSTOMER_ADDRESS_UPDATE_REQUEST",
  CUSTOMER_ADDRESS_UPDATE_SUCCESS: "CUSTOMER_ADDRESS_UPDATE_SUCCESS",
  CUSTOMER_ADDRESS_UPDATE_FAIL: "CUSTOMER_ADDRESS_UPDATE_FAIL",

  GET_CUSTOMERS_DROPDOWN_REQUEST: "GET_CUSTOMERS_DROPDOWN_REQUEST",
  GET_CUSTOMERS_DROPDOWN_SUCCESS: "GET_CUSTOMERS_DROPDOWN_SUCCESS",
  GET_CUSTOMERS_DROPDOWN_FAIL: "GET_CUSTOMERS_DROPDOWN_FAIL"
};
