// Inventory module types
export const InventoryMap = {
  INVENTORY_REQUEST: "INVENTORY_REQUEST",
  INVENTORY_SUCCESS: "INVENTORY_SUCCESS",
  INVENTORY_FAIL: "INVENTORY_FAIL",

  INVENTORY_ADD_REQUEST: "INVENTORY_ADD_REQUEST",
  INVENTORY_ADD_SUCCESS: "INVENTORY_ADD_SUCCESS",
  INVENTORY_ADD_FAIL: "INVENTORY_ADD_FAIL",

  INVENTORY_BULK_ADD_REQUEST: "INVENTORY_BULK_ADD_REQUEST",
  INVENTORY_BULK_ADD_SUCCESS: "INVENTORY_BULK_ADD_SUCCESS",
  INVENTORY_BULK_ADD_FAIL: "INVENTORY_BULK_ADD_FAIL",

  INVENTORY_UPDATE_REQUEST: "INVENTORY_UPDATE_REQUEST",
  INVENTORY_UPDATE_SUCCESS: "INVENTORY_UPDATE_SUCCESS",
  INVENTORY_UPDATE_FAIL: "INVENTORY_UPDATE_FAIL",

  INVENTORY_DELETE_REQUEST: "INVENTORY_DELETE_REQUEST",
  INVENTORY_DELETE_SUCCESS: "INVENTORY_DELETE_SUCCESS",
  INVENTORY_DELETE_FAIL: "INVENTORY_DELETE_FAIL",

  INVENTORY_SINGLE_REQUEST: "INVENTORY_SINGLE_REQUEST",
  INVENTORY_SINGLE_SUCCESS: "INVENTORY_SINGLE_SUCCESS",
  INVENTORY_SINGLE_FAIL: "INVENTORY_SINGLE_FAIL",
  INVENTORY_SINGLE_RESET: "INVENTORY_SINGLE_RESET",

  PRODUCTLIST_REQUEST: "PRODUCTLIST_REQUEST",
  PRODUCTLIST_SUCCESS: "PRODUCTLIST_SUCCESS",
  PRODUCTLIST_FAIL: "PRODUCTLIST_FAIL",

  SUBPRODUCTLIST_REQUEST: "SUBPRODUCTLIST_REQUEST",
  SUBPRODUCTLIST_SUCCESS: "SUBPRODUCTLIST_SUCCESS",
  SUBPRODUCTLIST_FAIL: "SUBPRODUCTLIST_FAIL",

  VENDORLIST_REQUEST: "VENDORLIST_REQUEST",
  VENDORLIST_SUCCESS: "VENDORLIST_SUCCESS",
  VENDORLIST_FAIL: "VENDORLIST_FAIL",

  LAST_INVENTORY_REQUEST: "LAST_INVENTORY_REQUEST",
  LAST_INVENTORY_SUCCESS: "LAST_INVENTORY_SUCCESS",
  LAST_INVENTORY_FAIL: "LAST_INVENTORY_FAIL"
};
